import { deepEqual } from 'fast-equals';

export * as Result from './result';
export * as RemoteData from './remote-data';
export * as SimpleDate from './simple-date';
export * as NumericFunctions from './numeric-functions';
export type { Result as ResultType } from './result';
export type { RemoteData as RemoteDataType } from './remote-data';

export {
    isEmpty,
    assert,
    isNotEmpty,
    assertExhaustive,
    undefinedToNull,
    modifyNullableValue,
    keyValueToObject,
    isEmptyString,
    isNotEmptyString,
    delay,
    deepCompare,
    overrideProps,
    getLastUrlSegment,
};

function assert(condition: unknown, msg?: string): asserts condition {
    if (condition === false) throw new Error(msg ?? 'Assertion failed');
}

function isEmpty<a>(value: a | undefined | null): value is null | undefined {
    return value === undefined || value === null;
}
function isNotEmpty<a>(value: a | undefined | null): value is a {
    return !isEmpty(value);
}
function isEmptyString(value: string | undefined | null): value is null | undefined {
    // consider empty string as null too
    return isEmpty(value) || value.trim() === '';
}
function isNotEmptyString(value: string | undefined | null): value is string {
    return !isEmptyString(value);
}

function assertExhaustive(_: never, message: string = 'Reached unexpected case in exhaustive switch'): never {
    throw new Error(message);
}

function undefinedToNull<T>(x: T | undefined): T | null {
    return x ?? null;
}

function overrideProps<T>(obj: T, updates: Partial<T>): T {
    return { ...obj, ...updates };
}

function modifyNullableValue<ValueType, FunctionReturnType>(
    fn: (arg1: ValueType) => FunctionReturnType,
    value: ValueType | null
): FunctionReturnType | null {
    if (isEmpty(value)) {
        return null;
    }
    return fn(value);
}

function keyValueToObject<T>(values: ReadonlyArray<{ key: string; value: T }>): Record<string, T> {
    const df: Record<string, T> = {};

    values.forEach((currentPair) => {
        df[currentPair.key] = currentPair.value;
    });

    return df;
}

function delay(delayInMs: number): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, delayInMs);
    });
}

function deepCompare<T extends Record<string, unknown>>(object1: T, object2: T): boolean {
    return deepEqual(object1, object2);
}

function getLastUrlSegment(): undefined | string {
    const paths = window.location.pathname.split('/').filter((entry) => entry !== '');
    return paths[paths.length - 1];
}
